
import { defineComponent, ref, inject, watchEffect, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_PRICE_GROUP, SEARCH_PRICE_GROUPS } from '@/modules/customer/price-groups/graphql/Queries';
import { DELETE_PRICE_GROUP } from '@/modules/customer/price-groups/graphql/Mutations';
import { useI18n } from 'vue-i18n';
import Search from '@/components/search/Search.vue';
import Table from '../../../../components/Table/Table.vue';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';
import { setPageHistory } from '@/core/helpers/toolbar';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'PriceGroup-list',
    components: { Search, Table, InnerLoader },
    setup() {
        const store = useStore();
        const priceGroupData: any = ref([]);
        const pagination = ref({}) as Record<any, any>;
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const emitter: any = inject('emitter');
        const loader = ref(false);
        const loading = ref(false);
        const system_locale = ref();
        const i18n = useI18n();
        const activeIndex = ref(-1);
        const searchObservable: any = ref(null);
        const observable: any = ref(null);
        const permissions = store.getters.getPermissions;
        const router = useRouter();
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.CREATED_AT',
                key: 'created_at'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const priceGroups = (page = 1, reload = false) => {
            if (searchObservable.value != null) {
                searchObservable.value?.unsubscribe();
                searchObservable.value = null;
            }
            loader.value = true;
            if (page == 1) observable.value?.unsubscribe();
            observable.value = Apollo.watchQuery({
                query: GET_PRICE_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                errorPolicy: 'all',
                variables: {
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload ? (currentPage.value = 1) : false;
                clickHandler.value = priceGroups;
                priceGroupData.value = data.price_groups.data;
                pagination.value = data.price_groups;
                loader.value = false;
            });
        };

        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        const searchHandler = (page = 1) => {
            if (observable.value != null) {
                observable.value?.unsubscribe();
                observable.value = null;
            }
            const searchData = store.getters.getSearchData;
            loader.value = true;
            searchObservable.value?.unsubscribe();
            searchObservable.value = Apollo.watchQuery({
                query: SEARCH_PRICE_GROUPS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    page: page,
                    limit: 10,
                    search_key: searchData.data.input,
                    locale: system_locale.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                priceGroupData.value = data.search_price_groups.data;
                pagination.value = data.search_price_groups;
                loader.value = false;
            });
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_PRICE_GROUP,
                        variables: { id: id },
                        update: (store, { data: { delete_price_group } }) => {
                            if (searchObservable.value != null) searchHandler(pagination.value.current_page);
                            else priceGroups(pagination.value.current_page);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        priceGroups();

        const handleEdit = (price_group: Record<any, any>) => {
            price_group = { ...price_group, current_page: pagination.value.current_page };
            emitter.emit('editPriceGroupAction', price_group);
        };

        const handleAssignment = (price_group: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });
            localStorage.setItem('pg_id', price_group.id);
            router.push({ name: 'assignment_price_group', params: { uuid: price_group.uuid } });
        };

        onMounted(() => {
            const pageHistory = store.getters.getPageHistory;
            document.addEventListener('click', handleClickOutside);

            if (pageHistory?.filter != undefined || pageHistory?.search_key != undefined) {
                searchHandler(pageHistory.page_number);
            } else {
                pageHistory.page_number == undefined ? priceGroups(1) : priceGroups(pageHistory.page_number);
            }
        });

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_PRICE_GROUPS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? searchData?.data?.select : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    locale: system_locale.value,
                    type: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_price_groups?.data[0]?.path, '_blank');
                    loading.value = false;
                }

                loading.value = false;
                sub.unsubscribe();
            });
        };

        onUnmounted(() => {
            observable.value?.unsubscribe();
            searchObservable.value?.unsubscribe();
        });

        return {
            pagination,
            priceGroupData,
            currentPage,
            permissions,
            columns,
            loading,
            loader,
            exportFile,
            priceGroups,
            searchHandler,
            handleCurrentChange,
            handleEdit,
            handleDelete,
            activeIndex,
            dropdownHandler,
            handleClickOutside,
            handleAssignment
        };
    }
});

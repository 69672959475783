import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PriceGroupList = _resolveComponent("PriceGroupList")!
  const _component_AddPriceGroup = _resolveComponent("AddPriceGroup")!
  const _component_EditPriceGroup = _resolveComponent("EditPriceGroup")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_PriceGroupList)
    ]),
    _createVNode(_component_AddPriceGroup),
    _createVNode(_component_EditPriceGroup)
  ], 64))
}
import { gql } from "graphql-tag";
import { PRICE_GROUP_FIELDS } from "./Fragments";

export const CREATE_PRICE_GROUP = gql`
	mutation CreatePriceGroup($input: PriceGroupInput!) {
		create_price_group(price_group: $input) {
			...PriceGroupFields
		}
	}
	${PRICE_GROUP_FIELDS}
`;

export const UPDATE_PRICE_GROUP = gql`
	mutation UpdatePriceGroup($input: PriceGroupInput!) {
		update_price_group(price_group: $input) {
			...PriceGroupFields
		}
	}
	${PRICE_GROUP_FIELDS}
`;

export const DELETE_PRICE_GROUP = gql`
	mutation DeletePriceGroup($id: Int!) {
		delete_price_group(id: $id) {
			...PriceGroupFields
		}
	}
	${PRICE_GROUP_FIELDS}
`;

export default { CREATE_PRICE_GROUP, UPDATE_PRICE_GROUP, DELETE_PRICE_GROUP };


import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import AddPriceGroup from "@/modules/customer/price-groups/components/AddPriceGroup.vue";
import EditPriceGroup from "@/modules/customer/price-groups/components/EditPriceGroup.vue";
import PriceGroupList from "@/modules/customer/price-groups/components/PriceGroupList.vue";

export default defineComponent({
	name: "PriceGroup",
	components: {
		AddPriceGroup,
		EditPriceGroup,
		PriceGroupList,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.PRICE_GROUPS",
				actionButton: {
					ability: "add_price_group",
					pageAction: {
						action: "addPriceGroupAction",
					},
					button: {
						icon: "bi bi-plus",
						openModal: true,
					},
				},
				breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.CUSTOMERS", link: "#" }, { name: "message.PRICE_GROUPS" }],
			});
		});
	},
});
